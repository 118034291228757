import Joi from "joi";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Button from "../../component/Button/Button";
import Error from "../../component/Error/Error";
import Input from "../../component/Input/Input";
import { apiCall } from "../../helpers/api";
import { passwordRegex } from "../../helpers/regex";
import { passwordValidationSet, setErrorMessages, validateRequired } from "../../helpers/validationMessages";
import ThemeLayout from "../../layout/themeLayout";

const ResetPassword = (props) => {
    const { token } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const [errors, setErrors] = useState({});

    const resetButton = useRef();
    const resetForm = useRef();
    const newPassword = useRef();
    const confirmPassword = useRef();

    const resetPassword = (e) => {
        e.preventDefault();

        setErrors({});
        const params = {
            path: `set-new-password`,
            method: "POST",
            data: {
                password: newPassword.current?.value,
                confirm_password: confirmPassword.current?.value,
            },
        };

        const { error } = resetPasswordSchema.validate(params.data, { abortEarly: false });

        if (error) {
            const t = setErrorMessages(error);
            console.log(t);
            setErrors(t);
        }

        if (params.data.password !== params.data.confirm_password && !errors.confirm_password) {
            return setErrors({ ...errors, confirm_password: "Passwords do not match" });
        }

        if (error) {
            return;
        }

        resetButton.current?.showLoader(true);

        params.data.token = token;
        delete params.data.confirm_password;

        dispatch(apiCall(params))
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);
                    history.replace("/login");
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        resetButton.current?.showLoader(false);
    };

    return (
        <ThemeLayout showHeader={false} className="gray-bg">
            <section>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="card my-5 col-lg-6 px-0">
                            <div className="card-header text-center">
                                <h3>Reset Password</h3>
                            </div>
                            <div className="card-body">
                                <form ref={resetForm} onSubmit={resetPassword}>
                                    <input type="hidden" name="token" value={token} />
                                    <div className="row">
                                        <div className={`col-lg-12`}>
                                            <Input className={`uk-input form-control`} type="password" ref={newPassword} name="password" styleType="floating" placeholder="New Password" />
                                            {errors["password"] && <Error marginTop="-12px" error={errors["password"]} />}
                                        </div>

                                        <div className={`col-lg-12`}>
                                            <Input
                                                className={`uk-input form-control`}
                                                type="password"
                                                ref={confirmPassword}
                                                name="confirm_password"
                                                styleType="floating"
                                                placeholder="Confirm Password"
                                            />
                                            {errors["confirm_password"] && <Error marginTop="-12px" error={errors["confirm_password"]} />}
                                        </div>

                                        <div className={`mb-0 col-lg-12 text-center`}>
                                            <Button ref={resetButton} htmlType="submit" type="button" className={`form__button`}>
                                                Reset Password
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
};

export default ResetPassword;

const resetPasswordSchema = Joi.object({
    password: Joi.string()
        .required()
        .pattern(passwordRegex)
        .messages({
            "string.empty": validateRequired("Password"),
        })
        .messages(passwordValidationSet),

    confirm_password: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Confirm Password"),
        }),
});
